<template>
<div class="container-fluid mt-3">
  <v-row>
  <v-progress-linear
          v-if="!b_IsLoaded"
          indeterminate
          color="primary"
  ></v-progress-linear>
  </v-row>
  <v-row>
    <v-col
      sm="12"
      lg="3"
      class="mb-4 controls"
    >
    
      <v-btn
        :loading="!b_IsLoaded"
        fab
        small
        absolute
        left
        color="primary"
        @click="$refs.calendar.prev()"
      >
        <v-icon dark>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        :loading="!b_IsLoaded"
        fab
        small
        absolute
        right
        color="primary"
        @click="$refs.calendar.next()"
      >
        <v-icon dark>mdi-chevron-right</v-icon>
      </v-btn>
      <br><br><br>
      <v-card class="mb-6">
        <v-system-bar
          color="primary"
          height=35
          dark
        >
          <span>{{ $t('general.new_absence') }}</span>
        </v-system-bar>
      </v-card>
      <v-select
        :disabled="!b_IsLoaded"
        :loading="!b_IsLoaded"
        v-model= "selectedType"
        :items="absenceType"
        :label="$t('general.absence_type')"
        :error-messages="selectedTypeErrors"
      ></v-select>
      <v-menu
        ref="startMenu"
        v-model="startMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="start"
        transition="scale-transition"
        min-width="290px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :disabled="!b_IsLoaded"
            v-model="startDateCHFormat"
            class="mt-3"
            :label="$t('general.start_date')"
            dense
            readonly
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="start"
          no-title
          :min="nowDate"
          @input="startMenu = false; $refs.startMenu.save(start)"
          first-day-of-week = "1"
          locale = "CH"
        >
        </v-date-picker>
      </v-menu>
      <br>
      <v-menu
          ref="endMenu"
          v-model="endMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="end"
          transition="scale-transition"
          min-width="290px"
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :disabled="!b_IsLoaded"
                v-model="endDateCHFormat"
                :label="$t('general.end_date')"
                readonly
                v-bind="attrs"
                v-on="on"
                class="mt-3"
                dense
                outlined
                hide-details
            ></v-text-field>
        </template>
        <v-date-picker 
          v-model="end" 
          :min="minEndDate"
          @input="endMenu = false; $refs.endMenu.save(end)" 
          no-title
          first-day-of-week = "1"
          locale = "CH"
        >

        </v-date-picker>
      </v-menu>
      <br>
      <v-text-field
        :disabled="!b_IsLoaded"
        :label="$t('general.comment')"
        v-model="s_Comment"
        outlined
      ></v-text-field>
      <div class="text-center">
          <v-btn :loading="!b_IsLoaded" class="mx-2" fab dark color="primary" small>
              <v-icon dark @click="setAbsence">mdi-plus</v-icon>
          </v-btn>
      </div>
    </v-col>
    <v-col
      sm="12"
      lg="9"
      class="pl-4"
    >
        <v-sheet height="600">
            <v-calendar
            :disabled="!b_IsLoaded"
            ref="calendar"
            v-model="start"
            :type="type"
            :start="start"
            :end="end"
            :min-weeks="minWeeks"
            :max-days="maxDays"
            :now="now"
            :dark="dark"
            :weekdays="weekdays"
            :first-interval="intervals.first"
            :interval-minutes="intervals.minutes"
            :interval-count="intervals.count"
            :interval-height="intervals.height"
            :interval-style="intervalStyle"
            :show-interval-label="showIntervalLabel"
            :short-intervals="shortIntervals"
            :short-months="shortMonths"
            :short-weekdays="shortWeekdays"
            :color="color"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="45"
            :event-color="getEventColor"
            @change="getAbsences"
            @click:event="showEvent"
            locale = "CH"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectedOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.s_Comment"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="red"
                  @click="deleteAbsence(selectedEvent.id)"
                  v-if="selectedEvent.type === 'absence' || selectedEvent.type === 'absence_provisional'"
                >
                  {{ $t('general.delete') }}
                </v-btn>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  {{ $t('general.cancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</div>
</template>

<script>
  const weekdaysDefault = [1, 2, 3, 4, 5, 6, 0]

  const intervalsDefault = {
    first: 0,
    minutes: 60,
    count: 24,
    height: 48,
  }

  const stylings = {
    default (interval) {
      return undefined
    },
    workday (interval) {
      const inactive = interval.weekday === 0 ||
        interval.weekday === 6 ||
        interval.hour < 9 ||
        interval.hour >= 17
      const startOfHour = interval.minute === 0
      const dark = this.dark
      const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

      return {
        backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
        borderTop: startOfHour ? undefined : '1px dashed ' + mid,
      }
    },
    past (interval) {
      return {
        backgroundColor: interval.past ? (this.dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
      }
    },
  }
  
import { get, post, del } from '../../../worker/worker-api'
import { validationMixin } from 'vuelidate'
import { required  } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
    name: 'InstructorCalendar',
    mixins: [validationMixin],
    data: () => ({
      dark: false,
      startMenu: false,
      start: new Date().toISOString().substr(0, 10),
      endMenu: false,
      end: new Date().toISOString().substr(0, 10),
      minWeeks: 1,
      now: null,
      events: [],
      colorEvent: 'orange lighten-3',
      type: 'month',
      mode: 'stack',
      weekdays: weekdaysDefault,
      intervals: intervalsDefault,
      maxDays: 7,
      styleInterval: 'default',
      color: 'primary',
      shortIntervals: true,
      shortMonths: false,
      shortWeekdays: false,
      nowDate: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      s_Comment: "",
      absenceType: [],
      selectedType: null,
      b_IsLoaded: true,
    }),
    computed: {
      intervalStyle () {
        return stylings[this.styleInterval].bind(this)
      },
      minEndDate() {
        const Lt_Start = new Date(this.start);
        const Lt_End = new Date(this.end);
        if(Lt_End < Lt_Start) {
          this.end = Lt_Start.toISOString().substr(0, 10);
          return Lt_Start.toISOString().substr(0, 10);
        }
        return this.nowDate;
      },
      ...mapState([
      ]),
      startDateCHFormat() {
        return this.getDateFormat(this.start);
      },
      endDateCHFormat() {
        return this.getDateFormat(this.end);
      },
      selectedTypeErrors () {
        const errors = []
        if (!this.$v.selectedType.$dirty) {
          return errors
        }
        !this.$v.selectedType.required && errors.push(this.$t('general.required_field'))
        return errors
      },
    },
    methods: {
      getEventColor (event) {
          return event.color
      },
      showIntervalLabel (interval) {
          return interval.minute === 0
      },
      getAbsences ({ start, end }) {
        this.b_IsLoaded=false;
        const events = [];
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/absence`, {
          month: start.month,
          year: start.year
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            let Ls_Color = this.colorEvent;
            for (let i = 0; i < response.length; i++) {
              const first = new Date(response[i].t_Start);
              const second = new Date(response[i].t_End);
              if(response[i].s_Type === 'lesson') Ls_Color = 'light-green';
              if(response[i].s_Type === 'absence_provisional') Ls_Color = 'orange lighten-3';
              if(response[i].s_Type === 'absence') Ls_Color = 'orange';
              events.push({
                  id: response[i].i_Id,
                  name: response[i].s_Title,
                  start: first,
                  end: second,
                  timed: 0,
                  color: Ls_Color,
                  type: response[i].s_Type,
                  s_Comment: response[i].s_Comment
              });
            }
            this.events = events;
            this.b_IsLoaded=true;
          })
          .catch(error => {
            console.log('Worker error: ', error)
            this.b_IsLoaded=true;
          })
      },
      setAbsence() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.b_IsLoaded=false; 
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/absence`, {
          s_Start: this.start,
          s_End: this.end,
          i_AbsenceType: this.selectedType,
          s_Comment: this.s_Comment
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.events.push({
              id: response.i_PersonAbsenceID,
              name: response.s_AbsenceTypeName,
              start: this.start,
              end: this.end,
              timed: 0,
              color: this.colorEvent,
              type: 'absence',
              s_Comment: this.s_Comment,
            });
            this.b_IsLoaded=true;
          })
          .catch(error => {
            console.log('Worker error: ', error)
            this.b_IsLoaded=true;
          })       
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      deleteAbsence(Ai_AbsenceID){
        if(confirm(this.$t('general.delete') + '?')){
          this.selectedOpen = false;
          this.b_IsLoaded=false;
          let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
          del(`${process.env.VUE_APP_SMT_API_URL}/teacher/${teacherID}/absence/${Ai_AbsenceID}`, {})
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            const searchEvent = (event) => event.id === Ai_AbsenceID;
            const Li_Index = this.events.findIndex(searchEvent);
            if(Li_Index > -1) this.events.splice(Li_Index, 1);
            this.b_IsLoaded=true;
          })
          .catch(error => {
            console.log('Worker error: ', error)
            this.b_IsLoaded=true;
          })
        }
      },
      getDateFormat(As_Date) {
        let Lo_Date = new Date(As_Date);
        let Ls_DateCHFormat = Lo_Date.getDate() + '.' + (parseInt(Lo_Date.getMonth()) + 1) + '.' + Lo_Date.getFullYear();
        return Ls_DateCHFormat;
      }
    },
    mounted() {
      this.absenceType = [
        {
           text: this.$t('general.holidays'),
           value: '2'
        },
        {
           text: this.$t('general.sick'),
           value: '1'
        }
      ]
    },
    validations: {
      selectedType: {
        required,
      }
    },
  }
</script>

<style scoped>
.controls {
  position: relative;
}
</style>